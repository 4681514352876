import React from "react"
import styled from "styled-components"

const GeneralManagerVacancy: React.FC = () => (
  <Container>
    <a
      href="https://october-cms-assets-prod.s3.eu-west-1.amazonaws.com/documents/GENERAL+MANAGER+ASSURANCE.pdf"
      target="_blank"
      download="GENERAL_MANAGER.pdf"
      style={{ fontSize: "18px", color: "blue", fontSizeAdjust: "bold" }}
      rel="noreferrer"
    >
      Download Job Description
    </a>
    <h3>JOB SPECIFICATION – GENERAL MANAGER</h3>
    <p>
      Workerslife Assurance Company Limited is a wholly owned subsidiary of the
      Workerslife Group of Companies - An employment opportunity as a General
      Manager currently exists within Workerslife Group.
    </p>
    <h4 style={{ paddingBottom: "16px" }}>1. PURPOSE OF THE JOB</h4>
    <p>
      Oversee underwriting, reinsurance, policy administration and portfolio
      management of life insurance business to contribute to the achievement of
      financial objectives of the organisation.
    </p>
    <h4 style={{ paddingBottom: "16px" }}>2. REQUIREMENTS FOR THE POSITION</h4>
    <Wrapper>
      <ul>
        <li>
          12 to 15 years’ experience in an insurance environment (specific to
          the Life environment).
        </li>
        <li>7 years of Managerial experience.</li>
        <li>
          Business knowledge, budgeting and creativity in problem solving.
        </li>
        <li>
          Previously approved as a Key Individual for an FSP or qualifying
        </li>
        <li>
          A Post-graduate Degree in Business Management/ Finance or an
          equivalent, RE 1 & 5.
        </li>
        <li>Life and/or Short term insurance underwriting experience</li>
      </ul>
    </Wrapper>
    <Wrapper>
      <h4>3. COMPETENCIES</h4>
      <ul>
        <li>Strong communication and presentation skills.</li>
        <li>Strong analytical skills.</li>
        <li>Advanced Excel skills.</li>
        <li>Entrepreneurial skills.</li>
        <li>Risk Management (including risk transfer mechanisms).</li>
        <li>
          Must meet the Fit and Proper requirements in terms of the FAIS Act.
        </li>
        <li>Experience of leading cross-functional teams.</li>
      </ul>
      <h4>4. CONTENT OF THE JOB</h4>
      <ul>
        <li>
          You will be responsible for the general management of business
          activities.
        </li>
        <li>
          You will be responsible for the formulation and management of
          operational plans in line with the
        </li>
        <li>
          You will be responsible for the identification and assessment of
          potential business opportunities (including the onboarding thereof) in
          line with the business strategy.
        </li>
        <li>
          You will be required to take a lead in market development and growth
          initiatives i.e. product integration and innovation, channel-specific
          agreements, and ongoing engagements with distribution services,
          outsourcing service providers and intermediaries.
        </li>
        <li>
          You will build and maintain strong relationships across industry, from
          regulators, industry bodies, service providers, distribution channels
          intermediaries, retail and corporate clients, etc.
        </li>
        <li>
          You will be responsible for the management of projects within the
          business.
        </li>
        <li>
          You will continuously scan the industry for the identification of
          potential risks and business trends.
        </li>
        <li>
          You will ensure compliance requirements are met and maintained with
          the Prudential Authority,Financial Sector Conduct Authority etc.
        </li>
      </ul>
    </Wrapper>
    <div style={{ paddingTop: "54px" }}>
      <p>
        Send applications to recruitment@workerslife.co.za +27 (0) 12 305 7400
        for the attention of HR Support.
      </p>
      <p>Closing date for applications is end of business on 22 July 2024.</p>
      <p>
        For further enquiries you may contact HR department at (012) 305 7400.
        For more information about Workerslife, please visit
        www.workerslife.co.za
      </p>
    </div>
  </Container>
)

export default GeneralManagerVacancy

const Container = styled.div`
  padding: 0 20px;

  h3 {
    margin-bottom: 38px;
    text-align: center;
    font-weight: 800;
  }
  p {
    font-family: "Sofia Pro";
    font-style: italic;
    font-weight: 400;
    text-align: center;
    color: #000000;
    margin-top: 0px;
    margin-bottom: 99px;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 58px;

    h3 {
      font-size: 52px;
      line-height: 69px;
    }

    p {
      font-size: 26px;
      line-height: 35px;
      margin-bottom: 40px;
    }
  }
`

const Wrapper = styled.div`
  display: "flex";
  flex-direction: "column";
  gap: "16px";
  padding-bottom: "16px";

  h5 {
    padding: 16px 0;
  }

  h4 {
    padding: 20px 0;
  }
`
